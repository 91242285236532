<template>
  <div class="discount">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="activeName" label="活动名称"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="140" prop="amount" label="充值金额(元)"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="140" prop="giveAmount" label="赠送金额(元)"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="80" prop="" label="状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.switch" active-color="#069BBC" inactive-color="#E2E2E2" @change="changeStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="danger" plain @click="handleDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog title="新增" :visible.sync="dialogVisible" :before-close="onCancel" :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="充值金额(元)" prop="amount">
              <el-input v-model="ruleForm.amount" placeholder="请输入充值金额(元)" @input="onInput($event, 1)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="赠送金额(元)" prop="giveAmount">
              <el-input v-model="ruleForm.giveAmount" placeholder="请输入赠送金额(元)" @input="onInput($event, 2)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="活动名称" prop="activeName">
              <el-input v-model="ruleForm.activeName" placeholder="请输入活动名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      submitLoding: false,
      memberCode: "",
      memberTel: "",
      tableData: [],
      ruleForm: {
        amount: "",
        giveAmount: "",
        activeName: "",
      },
      rules: {
        amount: [
          { required: true, message: "请输入充值金额", trigger: "blur" },
          {
            pattern: /^\d+(\.\d{1,2})?$/,
            message: "只能输入数字",
            trigger: "blur",
          },
        ],
        giveAmount: [
          { required: true, message: "请输入赠送金额", trigger: "blur" },
          {
            pattern: /^\d+(\.\d{1,2})?$/,
            message: "只能输入数字",
            trigger: "blur",
          },
        ],
        activeName: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
      },
      str1: "",
      str2: "",
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.selectShopRechargeActive, {
          params: {
            shopId: this.$store.state.userInfo.shopId || null,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            let list = res.data.result.map(e => {
              return {
                ...e,
                switch: e.status == "1" ? true : false,
              }
            })
            this.tableData = list
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleAdd() {
      this.str1 = ""
      this.str2 = ""
      this.dialogVisible = true
    },
    onInput(num, type) {
      let str = ""
      if (!num) {
        this.ruleForm.activeName = ""
        this.str1 = ""
        this.str2 = ""
      } else {
        if (type == 1) {
          this.str1 = `充${num}`
        }
        if (type == 2) {
          this.str2 = `送${num}`
        }
        str = this.str1 + this.str2
        this.ruleForm.activeName = str
      }
    },
    onCancel() {
      this.ruleForm = {
        amount: "",
        giveAmount: "",
        activeName: "",
      }
      this.dialogVisible = false
    },
    submitForm(formName) {
      this.submitLoding = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios
            .post(this.$api.insertShopRechargeActive, {
              shopId: this.$store.state.userInfo.shopId || null,
              amount: this.ruleForm.amount,
              giveAmount: this.ruleForm.giveAmount,
              activeName: this.ruleForm.activeName,
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success("新增成功")
              }
              this.ruleForm = {
                amount: "",
                giveAmount: "",
                activeName: "",
              }
              this.$refs.ruleForm.resetFields()
              this.dialogVisible = false
              this.getList()
            })
            .finally(() => {
              this.submitLoding = false
            })
        } else {
          this.submitLoding = false
          this.$message.error("提交失败")
          return false
        }
      })
    },
    handleDel(row) {
      this.$confirm(`即将删除该充值活动，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(this.$api.deleteShopRechargeActive, {
              id: row.id,
              status: "9",
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success("删除成功")
                this.getList()
              }
            })
        })
        .catch(() => {})
    },
    changeStatus(row) {
      const originalSwitch = row.switch
      this.$confirm(`该充值活动即将${row.switch ? "启用" : "停用"}，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.deleteShopRechargeActive, {
              id: row.id,
              status: row.switch ? "1" : "3",
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$set(row, "switch", originalSwitch)
                this.$message.success("操作成功")
              }
            })
            .catch(() => {
              this.$set(row, "switch", !originalSwitch)
            })
        })
        .catch(() => {
          this.$set(row, "switch", !originalSwitch)
        })
    },
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.discount {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;

      /deep/ .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
